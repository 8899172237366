<template>
  <div class="bg-white">
    <TsRow append-class="m-0 p-4">
      <TsColumn append-class="p-0 is-0 ms-1">
        <TsIcon name="humbleicons:cart" size="27" />
      </TsColumn>
      <TsColumn append-class="p-0 is-11 lg:flex lg:gap-2 lg:items-center">
        <TsTypography append-class="mb-0" as="h4">
          {{useTranslation("itemsToTrolley" , "Artikelen toegevoegd aan winkelwagen")}}
        </TsTypography>
        <slot name="title-tag">
          <TsTag
              variant="success"
              outlined
              append-class="outline-0 rounded-lg py-1"
              size="sm"
              :data-testid="`delivery-add-to-trolley-modal-channel-tag`"
          >
            {{
              props.titleTag || trolleyStore.previewed_product_v2?.direct_ship
                  ? useTranslation("directShip" , "Directschap")
                  : useTranslation("delivery" , "Levering")
            }}
          </TsTag>
        </slot>
      </TsColumn>
      <TsColumn append-class="p-0 is-1">
        <TsIcon
            name="bitcoin-icons:cross-filled"
            :data-testid="`delivery-add-to-trolley-modal-close-icon`"
            @click="handleModalClose"
            class="absolute top-3 right-3 text-idle-black hover:bg-info/10 rounded transition-colors cursor-pointer"
        />
      </TsColumn>
    </TsRow>

    <TsDivider append-class="w-full p-0 m-0" />

    <div
        class="p-5"
        v-if="props.loading"
        :data-testid="`delivery-add-to-trolley-modal-initial-loader`"
    >
      <TsBranchTransition v-for="n in 3" :key="n" />
    </div>

    <div v-else class="max-h-[70vh] overflow-y-auto">
      <slot>
        <TsRow append-class="flex-col p-3 m-0">
          <!-- Delivery variants trigger -->
          <template
              v-if="trolleyStore.previewed_product_v2?.variations?.length"
          >
            <TsTypography
                append-class="mb-3"
                as="h4"
                :data-testid="`delivery-add-to-trolley-modal-number-of-variants`"
            >{{
                useTranslation("selectProductTypes", "Select Product Variants")
              }}
              ({{
                trolleyStore.previewed_product_v2.variations.length
              }})</TsTypography
            >
            <TsButton
                :label="trolleyStore.previewed_product_v2?.pack_size"
                variant="secondary"
                icon="pepicons-pop:angle-down"
                :data-testid="`delivery-add-to-trolley-modal-select-variant-button`"
                iconPosition="right"
                append-class="mb-4 mt-0 bg-white justify-between w-full"
                iconClass="text-natural-silver-grey"
                outlined
                @click="handleDeliveryVariantsClick"
            />
          </template>

          <TsMedia>
            <TsMediaStart>
              <TsQuantityCounter
                  v-if="trolleyStore.trolley_line_items"
                  v-model="lineItem.quantity"
                  @increment="updateTrolleyDeliveryItemQuantity"
                  @decrement="updateTrolleyDeliveryItemQuantity"
                  @change="updateTrolleyDeliveryItemQuantity"
                  :data-testid="`delivery-add-to-trolley-modal-quantity-control`"
              />
              <TsTag
                  v-if="isOutOfStockForDelivery"
                  :label="outOfStockLabel"
                  :data-testid="`delivery-add-to-trolley-modal-out-of-stock-tag`"
                  variant="danger"
                  rounded
                  size="sm"
                  append-class="m-0 mt-2 py-0.5 px-5"
              />
              <TsTag
                  v-else
                  :label="`${trolleyStore.previewed_product_v2?.stockDetails?.delivery} ${useTranslation('available', 'beschikbaar')}`"
                  rounded
                  :data-testid="`delivery-add-to-trolley-modal-available-stock-tag`"
                  size="sm"
                  append-class="m-0 mt-2 py-0.5 px-5"
                  variant="info"
              />
            </TsMediaStart>

            <TsMediaContent append-class="ms-4">
              <TsRow
                  v-if="trolleyStore.previewed_product_v2?.prices?.raw ?? 0 > 0"
                  append-class="m-0"
                  :data-testid="`delivery-add-to-trolley-modal-recommended-retail-price-old`"
              >
              <div :class="`trolleyStore.previewed_product_v2.prices?.raw.gross < trolleyStore.previewed_product_v2.prices.was_price_raw && h-4`">
                <TsTypography
                    append-class="line-through leading-4 m-0 text-natural-grey"
                    v-if="(trolleyStore.previewed_product_v2?.prices && trolleyStore.previewed_product_v2?.prices?.raw && trolleyStore.previewed_product_v2?.prices?.was_price_formatted)
                    && trolleyStore.previewed_product_v2?.prices?.raw.gross < trolleyStore.previewed_product_v2?.prices.was_price_raw"
                >
                  {{
                    trolleyStore.previewed_product_v2?.prices
                        ?.was_price_formatted
                  }}
                </TsTypography>
              </div>
                <TsTypography
                    v-if="
                    trolleyStore.previewed_product_v2?.prices?.raw
                      .discount_percentage
                  "
                    size="sm"
                    weight="bold"
                    append-class="text-success leading-tight"
                    :data-testid="`delivery-add-to-trolley-modal-discount-percentage`"
                >
                  &nbsp; ({{
                    trolleyStore.previewed_product_v2?.prices?.formatted
                        .discount_percentage
                  }}
                  OFF)
                </TsTypography>
              </TsRow>
              <TsRow append-class="m-0 items-center gap-1">
                <TsTypography
                    variant="h2"
                    weight="bold"
                    append-class="text-idle-black mb-0 leading-6"
                    :data-testid="
                    rootStore.isIncVat
                      ? `delivery-add-to-trolley-modal-gross-price`
                      : `delivery-add-to-trolley-modal-net-price`
                  "
                >
                  {{
                    rootStore.isIncVat
                        ? trolleyStore.previewed_product_v2?.prices?.formatted
                            .gross
                        : trolleyStore.previewed_product_v2?.prices?.formatted.net
                  }}
                </TsTypography>
                <TsTypography
                    append-class="m-0 leading-4 text-natural-silver-grey"
                    size="xs"
                    :data-testid="`delivery-add-to-trolley-modal-formatted-per-unit-price`"
                >
                  {{
                    trolleyStore.previewed_product_v2?.prices?.formatted
                        .per_unit
                  }}
                </TsTypography>
              </TsRow>
              <TsTypography
                  variant="xs"
                  append-class="leading-4 text-natural-grey"
              >
                <span
                    :data-testid="`delivery-add-to-trolley-modal-vat-indicator-text`"
                >
                  {{ rootStore.isIncVat ? "Excl." : "Inc." }} VAT
                </span>
                <span
                    :data-testid="
                    rootStore.isIncVat
                      ? `delivery-add-to-trolley-modal-net-price`
                      : `delivery-add-to-trolley-modal-gross-price`
                  "
                >
                  {{
                    rootStore.isIncVat
                        ? trolleyStore.previewed_product_v2?.prices?.formatted.net
                        : trolleyStore.previewed_product_v2?.prices?.formatted.gross
                  }}
                </span>
              </TsTypography>
            </TsMediaContent>
          </TsMedia>

          <TsRow append-class="m-0">
            <TsDivider append-class="w-full mb-2" />
          </TsRow>

          <TsRow is-multiline append-class="m-0 py-1">
            <TsColumn append-class="p-0 is-2 md:is-1">
              <NuxtImg
                  src="/images/orderTruck.png"
                  width="45"
                  class="shrink-0"
              />
            </TsColumn>
            <TsColumn
                v-if="!isOutOfStockForDelivery"
                append-class="is-10 md:is-11 p-0"
            >
              <TsTypography
                  size="base"
                  weight="semibold"
                  append-class="text-idle-black"
              >
                {{ useTranslation("deliveryBy", "Levering door") }}
                <span class="text-primary font-semibold">{{useTranslation("tomorrow" , "Morgen")}}</span>
              </TsTypography>
              <TsTypography size="sm" append-class="text-idle-black">
                {{useTranslation("orderWithin" , "Bestel binnen")}}
                <span class="text-idle-black font-semibold">10 {{useTranslation("pm" , "pm")}}</span>
              </TsTypography>
            </TsColumn>
            <TsColumn
                v-else
                append-class="is-10 md:is-11 p-0 flex items-center"
            >
              <TsTypography
                  size="base"
                  weight="semibold"
                  append-class="text-danger"
              >
              {{outOfStockLabel}}
                <span class="text-idle-black font-semibold">{{useTranslation("forDelivery" , "voor levering")}}</span>
              </TsTypography>
            </TsColumn>
          </TsRow>
        </TsRow>
      </slot>

      <div class="bg-natural-soft-white">
        <TsColumn append-class="pb-1">
          <TsTypography
              weight="semibold"
              append-class="text-idle-black pl-3 leading-5"
          >
          {{useTranslation("youMayAlsoLike" , "Misschien vind je het ook leuk")}}
          </TsTypography>
        </TsColumn>
        
        <TsRow
            append-class="m-0 px-3 pb-1 flex overflow-x-auto whitespace-nowrap no-scrollbar"
            monetate-id="collection-delivery-you-may-also-like"
        >
        
          <TsModalProductCard v-for="product in products" :key="product.code" :product="product"
            :addToTrolleyLabel="useTranslation('addToTrolley', 'Add to Trolley')" />
        </TsRow>
      </div>
    </div>

    <TsRow v-if="isOutOfStockForDelivery" append-class="m-0 justify-center px-4">
      <TsColumn class="sm:px-36">
          <TsButton
            v-if="isSubscribedToStock(trolleyStore.previewed_product_v2!.code)"
            icon="material-symbols:unsubscribe-outline"
            :label="unsubscribeLabel"
            data-testid="collection-add-to-trolley-stock-notification-unsubscribe-cta"
            :is-loading="isStockUnsubscribeCTAloading"
            @click="handleStockUnsubscribe(trolleyStore.previewed_product_v2!.code)"
            outlined
            block
          />
          <TsButton
            v-else
            icon="mdi:bell-outline"
            :label="notifyMeLabel"
            data-testid="collection-add-to-trolley-out-of-stock-notification-cta"
            @click="emit('notify', trolleyStore.previewed_product_v2!.code, trolleyStore.previewed_product_v2!.full_name || trolleyStore.previewed_product_v2!.name)"
            block
          />
        <div></div>
      </TsColumn>
    </TsRow>

    <TsRow v-else append-class="m-0 justify-between p-2">
      <TsColumn>
        <TsButton
            block
            :label="continueShoppingCTALabel"
            data-testid="delivery-add-to-trolley-continue-shopping-cta"
            size="sm"
            outlined
            @click="emit('close')"
        />
      </TsColumn>
      <TsColumn>
        <TsButton
            block
            icon="mdi:trolley-outline"
            :label="goToTrolleyCTALabel"
            data-testid="delivery-add-to-trolley-go-to-trolley-cta"
            @click="emit('goToTrolley')"
            size="sm"
        />
      </TsColumn>
    </TsRow>

    <!-- Loader Overlay -->
    <div
        v-if="trolleyItemProcessing"
        class="absolute inset-0 bg-idle-black/15 z-10 flex justify-center items-center rounded-lg"
        data-testid="delivery-add-to-trolley-processing-loader-overlay"
    >
      <!-- Progress Bar -->
      <div
          class="absolute top-0 left-0 z-50 h-1.5 transition-all bg-success"
          data-testid="delivery-add-to-trolley-progress-bar-loader"
          :style="{ width: progressBarWidth + '%' }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductService } from "~/services/product.service";
import { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";
import { useTranslation } from "~/composables/useTranslation";

const trolleyStore = useTrolleyStore();
const rootStore = useRootStore();
const monetateStore = useMonetateStore();
const products = ref([])

onMounted(async () => {
  if (!monetateStore.categories_you_may_also_like_ids) return;
  const data_ids = monetateStore.categories_you_may_also_like_ids.map(item => item)
  if(data_ids.length === 0){
    return false;
  }
  products.value = await new ProductService().getProducts(data_ids);
})

type Props = {
  loading?: boolean;
  titleTag?: string;
};

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  close: any;
  notify: [code: string, name: string];
  goToTrolley: any;
  clickVariants: any;
}>();

// OOS Notifications
const isStockUnsubscribeCTAloading = ref(false);

function isSubscribedToStock(code: string) {
  const user: any = useAuthStore().user;
  if (!user || !user.id) return false;

  return useAccountStore().stock_notifications.some(
    (notification: any) => notification.product_id === code
  );
}

async function handleStockUnsubscribe(productCode: string) {
  isStockUnsubscribeCTAloading.value = true;
  await useAuthStore().onStockUnsubscribe(productCode);
  isStockUnsubscribeCTAloading.value = false;
}

// clicking on delivery variants
function handleDeliveryVariantsClick() {
  trolleyStore.delivery_product_variants = [];
  if (!trolleyStore.previewed_product_v2?.variations.length) return;

  trolleyStore.preview_overlay_delivery_visible = false;
  trolleyStore.delivery_variants_modal_visible = true;
  const stockStore = useStockStore();

  stockStore
      .getStockForProducts(trolleyStore.previewed_product_v2?.variations)
      .then((response) => {
        trolleyStore.delivery_product_variants = response.map((product) => ({
          ...product,
          quantity: 0,
          channel: product.direct_ship
              ? TrolleyChannel.Directship
              : TrolleyChannel.Delivery,
        }));
      });
}

const isOutOfStockForDelivery = computed<boolean>(() => {
  // Previously, we were comparing `trolleyStore.delivery_quantity_counter` instead of `lineItem.quantity`
  // to sync with trolley now
  return (
      trolleyStore.previewed_product_v2?.stockDetails?.delivery !== undefined &&
      lineItem.quantity >
      trolleyStore.previewed_product_v2?.stockDetails?.delivery
  );
});

function handleModalClose() {
  emit("close");
}

const trolleyItemProcessing = ref(false);

const lineItem = reactiveComputed<any>(() => {
  if (trolleyStore.previewed_product_v2?.direct_ship) {
    // find directship trolley item ( fallback: quantity = 1 )
    return (
        trolleyStore.trolley_line_items?.Directship.find(
            (line) => line.product_code === trolleyStore.previewed_product_v2?.code
        ) ?? { quantity: 1 }
    );
  } else {
    // find delivery trolley item ( fallback: quantity = 1 )
    return (
        trolleyStore.trolley_line_items?.Delivery.find(
            (line) => line.product_code === trolleyStore.previewed_product_v2?.code
        ) ?? { quantity: 1 }
    );
  }
});

const updateTrolleyDeliveryItemQuantity = async () => {
  if (isOutOfStockForDelivery.value || !lineItem) return;

  trolleyItemProcessing.value = true;
  // update product quantity in trolley
  await trolleyStore.updateQuantity(lineItem);
  trolleyItemProcessing.value = false;
};

// for loading overlay
watch(trolleyItemProcessing, (isProcessing) => {
  if (isProcessing) startProgress();
  else resetProgress();
});

const progressBarWidth = ref(0);
let intervalId: NodeJS.Timeout | null = null;

const startProgress = () => {
  progressBarWidth.value = 0;
  intervalId = setInterval(() => {
    if (progressBarWidth.value < 100) {
      progressBarWidth.value += 1;
    } else {
      clearInterval(intervalId!);
    }
  }, 5);
};

const resetProgress = () => {
  if (intervalId) {
    clearInterval(intervalId);
  }
  progressBarWidth.value = 0;
};

const continueShoppingCTALabel = useTranslation(
    "continueShopping",
    "Ga door met winkelen"
);
const goToTrolleyCTALabel = useTranslation("goToTrolley", "Ga naar karretje");
const addToTrolley = useTranslation("addToTrolley" , "Toevoegen aan karretje");
const outOfStockLabel = useTranslation("outOfStock" , "Niet op voorraad");
const notifyMeLabel = useTranslation("notifyMe", "Breng mij op de hoogte");
const unsubscribeLabel = useTranslation("unsubscribe", "Uitschrijven");
</script>
